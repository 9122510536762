//import auth from "./firebaseApp";
import { 
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  getAuth,
} from 'firebase/auth';

import { firestoreMethods } from './firestoreMethods';
//import { utilMethods } from '../util/utilMethods';


// firebase Auth helper methods go here... 
export const authMethods = {

  isAuthUser: async (userEmail) => {
    const authUsers = await firestoreMethods.get('users');
    const authEmails = authUsers.map(u => u.email)
    const isAuth = authEmails.includes(userEmail);
    //console.log('auth', isAuth, authUsers, authEmails);
    return isAuth;
  },

  getUserPermissions: async (userEmail, setACUser, setCurrentCarrierId) => {
    const filter = {name: 'email', op: '==', value: userEmail}
    const newData = await firestoreMethods.get("users", filter);
    setACUser(newData[0]);

    const storedCarrier = localStorage.getItem('carrierId');
    const carrierId = newData[0]?.carriers?.includes(storedCarrier) ? storedCarrier : (newData[0]?.carriers?.[0] || 'USLI')
    setCurrentCarrierId(carrierId)
  },

  signInWithGoogle: async (setErrors) => {
    try {
      const auth = getAuth();
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const isAuthorized = await authMethods.isAuthUser(user.email);
      console.log('isAuthorized', isAuthorized)
      if (isAuthorized) {
        // Handle authorized user
        console.log('Welcome, ' + user.displayName);
        // ... integrate user with your application
      } else {
        await signOut(auth);

        console.log('Signin failed: Your domain is not authorized.');
        // Display an error message to the user
      }
      setErrors(null);
    } catch (err) {
      console.log('sign-in error', err)
      setErrors(["Sign-in with google failed."]);
    }   
  },

  signOut: async () => {
    const auth = getAuth();
    signOut(auth);
  }, 
}
