import { createContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";

import { authMethods } from './authMethods';
//import { utilMethods } from '../util/utilMethods';

//import auth from "./firebaseApp";


export const firebaseAuth = createContext();

const AuthProvider = (props) => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const [currentCarrierId, setCurrentCarrierId] = useState();

  const [debug, setDebug] = useState(false);
  const [acUser, setACUser] = useState(false);
  
  const signInWithGoogle = (setErrors) => {
    authMethods.signInWithGoogle(setErrors);
  }

  const signOut = () => {
    authMethods.signOut();
  }

  useEffect(()=> {
    const fetchUserPermissions = async (user) => {
      try {
        await authMethods.getUserPermissions(user.email, setACUser, setCurrentCarrierId)
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } 
    };

    if (user) {
      fetchUserPermissions(user)
    }

  }, [user, setACUser, setCurrentCarrierId])

  //console.log('currentCarrierId', currentCarrierId)
  
  return (
    <firebaseAuth.Provider
    value={{
      user,
      acUser, 
      currentCarrierId,
      setCurrentCarrierId,
      loading,
      signInWithGoogle,
      signOut,
      debug,
      setDebug,
    }}>
      {props.children}
    </firebaseAuth.Provider>
    
  );
};

export default AuthProvider;
//export default withRouter(AuthProvider);


