import { createContext, useState, useEffect, useContext } from 'react';
import { firebaseAuth } from '../firebase/AuthProvider';
import { stepMethods } from '../util/stepMethods';
import { firestoreMethods } from './firestoreMethods';

export const firestoreAudits = createContext();

const AuditProvider = (props) => {

  const { user, currentCarrierId } = useContext(firebaseAuth);

  const [audit, setAudit] = useState();
  //const [auditSteps, setAuditSteps] = useState();

  const [refreshAudit, setRefreshAudit] = useState(false);
  const [refreshAuditSteps, setRefreshAuditSteps] = useState(true);
  const [refreshStep, setRefreshStep] = useState(false);


  const [viewAudits, setViewAudits] = useState(null);
  const [viewAllSteps, setViewAllSteps] = useState(null);

  const [refreshViewAudits, setRefreshViewAudits] = useState(true);

  const [activeSlices, setActiveSlices] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();


  //============= steps
  const [ allSteps, setAllSteps ] = useState(null);
  const [step, setStep] = useState(null);

  //============= bakery
  const [bakeryStepDefs, setBakeryStepDefs] = useState(null);
  const [bakerySteps, setBakerySteps] = useState(null);

  const isAdmin = window.location.pathname.toLowerCase().startsWith('/admin') && user;
  const isBaker = window.location.pathname.toLowerCase().startsWith('/baker') && user;

  // getSlices
  useEffect(() => {
    const fetchData = async () => {
      const slicesObj = await firestoreMethods.getD("bakes", 'activeSlices');
      setActiveSlices(slicesObj?.slices);
    };
    if (isAdmin) fetchData();
  }, [isAdmin]);

  // refresh ALL AUDITS
  useEffect(() => {
    const fetchData = async () => {
      process.env.NODE_ENV === 'development' && console.log("UE-: refresh ALL AUDITS for carrier:", currentCarrierId);

      const filters = [];
      if (isAdmin && activeSlices?.length > 0) {
        filters.push({ name: 'sliceID', op: 'in', value: activeSlices });
      }
      if ((isAdmin || isBaker) && currentCarrierId) {
        filters.push({ name: 'carrierCompany', op: '==', value: currentCarrierId });
      }

      const newData = await firestoreMethods.getMT("audits", filters);
      setViewAudits(newData);

      // GET ALL STEPS (TODO: consider if this needs carrier filtering)
      setViewAllSteps({});

      const d = new Date();
      setLastUpdated(d);
      setRefreshViewAudits(false);

      //console.log("AuditProvider: viewAudits: ", newData);
    };

    if (isAdmin && refreshViewAudits && activeSlices?.length > 0) fetchData();
      
  }, [isAdmin, isBaker, refreshViewAudits, activeSlices, currentCarrierId]); // Trigger when refreshViewAudits or currentCarrierId changes

  // refresh single audit
  useEffect(() => {
    const fetchData = async () => {
      process.env.NODE_ENV === 'development' && console.log("UE-: refresh SINGLE AUDIT: ", audit?.id);

      if (audit?.id) {
        const newData = await firestoreMethods.getD("audits", audit.id);
        setAudit({ ...newData, lastUpdated: new Date() });

        if (!isBaker && viewAudits) {
          const objectIndex = viewAudits.findIndex((a) => a.id === audit.id);
          if (objectIndex !== -1) {
            const newArray = [...viewAudits];
            newArray[objectIndex] = { ...newData, lastUpdated: new Date() };
            setViewAudits(newArray);
          } else {
            console.error('Object not found in the array');
          }
        }
      }
      setRefreshAudit(false);
    };

    if ((isAdmin || isBaker) && refreshAudit && audit?.id) fetchData();
  }, [isAdmin, isBaker, refreshAudit, viewAudits, audit?.id]);


  // ======================== STEPS ========================
  // ======================== STEPS ========================
  // ======================== STEPS ========================

  // fetch All audit steps
  // THIS IS NECESSARY TO UPDTATE STEPS AS USER GOES FROM AUDIT TO AUDIT
  useEffect(() => {
    const fetchAuditSteps = async () => {
      process.env.NODE_ENV === 'development' && console.log("UE-1: refresh ALL STEPS for ", audit?.auditCode);

      if (audit?.auditCode) {
        const filter = { name: 'auditCode', op: '==', value: audit.auditCode };
        const newData = await firestoreMethods.getMT("asteps", [filter]); // Pass filter as an array
        if (newData) {
          const augmentedSteps = [];
          for (const newStep of newData) {
            augmentedSteps.push({ ...newStep, ...stepMethods.getStepDetails(newStep.type, audit) });
          }
          setAllSteps(stepMethods.sortSteps(augmentedSteps));
          setRefreshAuditSteps(false);
          setRefreshStep(true);
        }
      }
    };

    if (audit) fetchAuditSteps();
  }, [audit, setRefreshAuditSteps, setAllSteps]);


  // fetch All audit steps->ONLY WORKS WHEN REFRESH ALL STEPS IS CALLED
  useEffect(() => {
    const fetchAuditSteps = async () => {
      process.env.NODE_ENV === 'development' && console.log("UE-2:  refresh ALL STEPS for ", audit?.auditCode);

      if (audit?.auditCode) {
        const filter = { name: 'auditCode', op: '==', value: audit.auditCode };
        const newData = await firestoreMethods.getMT("asteps", [filter]); // Pass filter as an array
        if (newData) {
          const augmentedSteps = [];
          for (const newStep of newData) {
            augmentedSteps.push({ ...newStep, ...stepMethods.getStepDetails(newStep.type, audit) });
          }
          setAllSteps(stepMethods.sortSteps(augmentedSteps));
          setRefreshAuditSteps(false);
          setStep(null)// close down the step, so that a rerender can happen upon step open
        }
      }
    };

    if (audit && refreshAuditSteps) fetchAuditSteps();
  }, [refreshAuditSteps, setAllSteps, setStep, audit, setRefreshAuditSteps]);

  // WHENEVER ALL STEPS GETS UPDATED, REFETCH THE LATEST STEP
  useEffect(() => {
    const updateStep = async () => {
      const s = allSteps?.find(st => st.id === step?.id);
      process.env.NODE_ENV === 'development' && console.log("UE-: refetch current step because ALL STEPS updated ", step?.auditCode, 'step: ', step);
      if (s) setStep(s);
      setRefreshStep(false);
    };

    if (allSteps && step?.id && refreshStep) updateStep();
  }, [allSteps, setStep, step, refreshStep]);


  // ======================== IS BAKER ========================
  // ======================== IS BAKER ========================
  // ======================== IS BAKER ========================
  useEffect(() => {
    const fetchData = async () => {
      const btypesData = await firestoreMethods.get("bakery");
      setBakeryStepDefs(btypesData);
    };

    if (isBaker || isAdmin) fetchData();
  }, [isBaker, isAdmin]);

  useEffect(() => {
    const fetchData = async () => {
      const filters = [];
      if (isBaker) {
        filters.push({ name: 'baker', op: '==', value: true });
        filters.push({ name: 'bakerStepActive', op: '==', value: true });
      }
      if (isBaker && currentCarrierId) {
        filters.push({ name: 'carrierCompany', op: '==', value: currentCarrierId });
      }
      const newData = await firestoreMethods.getMT("asteps", filters);
      setBakerySteps(newData);
    };

    if (isBaker && currentCarrierId) fetchData();
  }, [isBaker, currentCarrierId]);

  // ======================== UPDATE STEPS ========================
  const updateBakerySteps = async (updateId, updateStep) => {
    const objectIndex = bakerySteps.findIndex((s) => s.id === updateId);
    if (objectIndex !== -1) {
      const newArray = [...bakerySteps];
      newArray[objectIndex] = updateStep;
      setBakerySteps(newArray);
    } else {
      console.error('Object not found in the array');
    }
  }

  // ======================== RETURN ========================
  // ======================== RETURN ========================
  // ======================== RETURN ========================

  return (
    <firestoreAudits.Provider
      value={{
        activeSlices,
        setActiveSlices,
        viewAudits,
        setViewAudits,
        viewAllSteps, // TODO: PROB CAN DELETE

        allSteps,
        setAllSteps,
        step, setStep,

        audit,
        setAudit,

        refreshAudit,
        refreshViewAudits,
        refreshAuditSteps,
        setRefreshAudit,
        setRefreshViewAudits,
        setRefreshAuditSteps,
        bakeryStepDefs,
        bakerySteps,
        updateBakerySteps,
        lastUpdated,
      }}
    >
      {props.children}
    </firestoreAudits.Provider>
  );
};

export default AuditProvider;